import { inject, Injectable } from '@angular/core';
import { Router, NavigationEnd, UrlTree, RouterStateSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationHistoryService {
  private readonly router = inject(Router);

  private readonly mainRouteSnapshots: Map<string, RouterStateSnapshot> = new Map();

  constructor() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (this.isMainRoute(this.router.routerState.snapshot)) {
        // Remove other main route filters, so only the last visited is retained
        // Remove this to retain filters across page types
        this.mainRouteSnapshots.clear();

        this.mainRouteSnapshots.set(
          this.compileUrlPath(this.router.routerState.snapshot.url),
          this.router.routerState.snapshot
        );
      }
    });
  }

  /**
   * Looks up if the url is in memory and returns the relevant UrlTree to navigate to that page, including the query.
   * Returns null when such memory entry does not exist.
   * @param url
   */
  public getMainUrlTreeFromUrl(url: string): UrlTree | null {
    const segments = this.compileUrlSegments(url);

    if (!segments.length) {
      return null;
    }

    const routeSnapshot = this.mainRouteSnapshots.get(`/${segments[0]}`);
    if (!routeSnapshot) {
      return null;
    }

    return this.compileUrlTree(routeSnapshot);
  }

  /**
   * Return the path portion of a (relative) URL
   * @param path
   * @private
   */
  private compileUrlPath(path: string): string {
    const url = new URL(path, 'https://non-relevant-portion.com');
    return url.pathname;
  }

  /**
   * Creates the UrlTree from a snapshot that can be used as RouterLink
   * @param snapshot
   * @private
   */
  private compileUrlTree(snapshot: RouterStateSnapshot): UrlTree {
    return this.router.createUrlTree([this.compileUrlPath(snapshot.url)], { queryParams: snapshot.root.queryParams });
  }

  /**
   * Turns '/path/segments` into ['path', 'segments']
   * @param url
   * @private
   */
  private compileUrlSegments(url: string): Array<string> {
    return this.compileUrlPath(url)
      .split('/')
      .filter((segment) => segment.length > 0);
  }

  /**
   * Check if the immediate parent is the root ('/') route
   * @param route
   * @private
   */
  private isMainRoute(route: RouterStateSnapshot): boolean {
    const segments = this.compileUrlSegments(route.url);
    return segments.length === 1;
  }
}
